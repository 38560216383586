import { toast } from "react-toastify";
import { trackAnalytics } from "../services/analytics";
import { SegmentEvents } from "../constants/events.constants";
import { getLoginUserEmail } from "../../../../utils/utils";
import { SNACKBAR_ALERT_CASE_COLOR, SNACKBAR_INFO_CASE_COLOR, SNACKBAR_POSITIVE_CASE_COLOR, WARNING_ICON_COLOR } from "../constants/color.constants";
import DangerIcon from "../../../../assets/img/dangerIcon";
import { NOTIFICATION_TYPE } from "../constants/notification.constants";
import AlertPositiveCase from "../assets/images/alertPositiveCase";
import { CALENDAR_CONSTANTS } from "../constants/filters.constants";
import { userTypes } from "../../../../utils/constants/user-types.constants";

export const showToastMessage = (
  message,
  isSuccess = true,
  autoClose = 10000
) => {
  if (isSuccess) {
    toast.success(message, {
      hideProgressBar: true,
      autoClose: autoClose,
    });
  } else {
    toast.error(message, {
      hideProgressBar: true,
      autoClose: autoClose,
      closeOnClick: true,
    });
  }
};

export const getReqParams = (params) => {
  let param = "";
  for (const [key, value] of Object.entries(params)) {
    let sanitizedValue = value;
    if (typeof value === 'string') {
      sanitizedValue = value.replace(/\t/g, ' ');
    }
    param = param ? param + '&' + key + '=' + sanitizedValue : '?' + key + '=' + sanitizedValue;
  }
  return param;
};

export const numberWithIndicator = (fieldValue) => {
  if (!fieldValue) return "";
  if (fieldValue === 1) return fieldValue + "st";
  if (fieldValue === 2) return fieldValue + "nd";
  if (fieldValue === 3) return fieldValue + "rd";
  return fieldValue + "th";
}

export const currencyFormat = (num) => {
  num = num ? Number(num) : num;
  return num ? num?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }) : 'NA';
};

export const percentageFormat = (num) => {
  return !(num === undefined || num === null || num === "") ? num + '%' : 'NA';
};

// export const formatedTime = (value)=>{
//  return value ?  dayjs(value).format("hh:mm A"): value;
// }

export function getDateTime(dateTimeStr) {
  const dateObject = new Date(dateTimeStr);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  let hours = dateObject.getUTCHours();
  const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
  const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');

  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = String(hours).padStart(2, '0');

  return `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${period}`;
}

export const debounced = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);

  };
}

export const getNumberSuffix = (num) => {
  let j = num % 10,
    k = num % 100;
  if (j === 1 && k !== 11) {
    return num + "st";
  }
  if (j === 2 && k !== 12) {
    return num + "nd";
  }
  if (j === 3 && k !== 13) {
    return num + "rd";
  }
  return num + "th";
}

export const formatNumbers = (data) => {
  return data ? data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : data;
}

export const queryStringToObject = (queryString) => {
  return Object.fromEntries(new URLSearchParams(queryString));
};

export const getCallEvent = (data, source) => {

  const email = getLoginUserEmail();

  const trackData = {
    dealer_id: data?.dealerId,
    dealer_code: data?.dealerCode,
    source,
    client_correlation_id: data?.clientCorrelationId,
    email_id: email,

  }
  trackAnalytics(SegmentEvents.Viz_clicked_call_button, trackData);
}

export const hexToRGBA = (hex, opacity) => {
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
}

export const getNotificationLevel = (level) => {
  switch (level) {
    case NOTIFICATION_TYPE.CRITICAL:
      return {
        color: SNACKBAR_ALERT_CASE_COLOR,
        icon: DangerIcon,
      }

    case NOTIFICATION_TYPE.WARNING:
      return {
        color: WARNING_ICON_COLOR,
        icon: DangerIcon,
      }

    case NOTIFICATION_TYPE.INFO:
      return {
        color: SNACKBAR_INFO_CASE_COLOR,
        icon: DangerIcon,
      }

    case NOTIFICATION_TYPE.SUCCESS:
      return {
        color: SNACKBAR_POSITIVE_CASE_COLOR,
        icon: AlertPositiveCase,
      }

    default:
      return {
        color: SNACKBAR_ALERT_CASE_COLOR,
        icon: DangerIcon,
      }

  }

}

export const generateTrackData = (allCarsData, dealerCode, page, action) => {
  let trackData = {
    action,
    data: allCarsData.map(carDetails => ({
      appointmentId: carDetails.appointmentDetails.appointmentId,
      recoScore: carDetails.recoScore,
      auctionId: carDetails?.ocbDetails?.auctionId || carDetails?.liveAuctionDetails?.auctionId || carDetails?.negoDetails?.auctionId,
      ocbPrice: carDetails?.ocbDetails?.requestedPrice || carDetails?.liveAuctionDetails?.currentAuctionHighestBid || carDetails?.negoDetails?.ocbPrice,
      timeRemaining: carDetails?.ocbDetails?.endTime || carDetails?.liveAuctionDetails?.endTime || carDetails?.negoDetails?.auctionEndTime,
      tvcDealerId: dealerCode,
      pageCount: page.skip + 1,
    }))
  }
  return trackData
};

export const convertMilliseconds = (ms) => {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

  return `${hours}h ${minutes}m ${seconds}s`;
}

export const getPredefinedDateRange = (selectedValue) => {
  const today = new Date();
  const startOfDay = new Date(today.setHours(0, 0, 0, 0));
  const endOfDay = new Date(today.setHours(23, 59, 59, 999));

  switch (selectedValue) {
    case CALENDAR_CONSTANTS[0].id:
      return { start: startOfDay, end: endOfDay };
    case CALENDAR_CONSTANTS[1].id:
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return {
        start: new Date(yesterday.setHours(0, 0, 0, 0)),
        end: new Date(yesterday.setHours(23, 59, 59, 999)),
      };
    case CALENDAR_CONSTANTS[2].id:
      const last7Days = new Date(today);
      last7Days.setDate(today.getDate() - 6);
      return {
        start: new Date(last7Days.setHours(0, 0, 0, 0)),
        end: endOfDay,
      };
    case CALENDAR_CONSTANTS[3].id:
      const last30Days = new Date(today);
      last30Days.setDate(today.getDate() - 29);
      return {
        start: new Date(last30Days.setHours(0, 0, 0, 0)),
        end: endOfDay,
      };
    default:
      return { start: null, end: null };
  }
};

export const convertEpochTime = (epochTime) => {
  const currentTime = Date.now();
  const timeDiffInSeconds = Math.floor((currentTime - epochTime) / 1000);
  const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);

  if (timeDiffInSeconds < 60) {
    return `${timeDiffInSeconds} second${timeDiffInSeconds === 1 ? '' : 's'} ago`;
  } else if (timeDiffInMinutes < 60) {
    return `${timeDiffInMinutes} minute${timeDiffInMinutes === 1 ? '' : 's'} ago`;
  } else if (timeDiffInMinutes < 1440) {
    const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
    return `${timeDiffInHours} hour${timeDiffInHours === 1 ? '' : 's'} ago`;
  } else if (timeDiffInMinutes < 43200) {
    const timeDiffInDays = Math.floor(timeDiffInMinutes / 1440);
    return `${timeDiffInDays} day${timeDiffInDays === 1 ? '' : 's'} ago`;
  } else {
    const timeDiffInMonths = Math.floor(timeDiffInMinutes / 43200);
    return `${timeDiffInMonths} month${timeDiffInMonths === 1 ? '' : 's'} ago`;
  }
}

export const formatToReadableDate = (date) => {
  return date?.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
};

export const formatDateToDMY = (date) => {
  return date?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });

};

export function convertEpochToCustomFormat(epochTime) {
  const date = new Date(epochTime);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;

  return `${hours}:${minutes}${ampm}, ${day}/${month}/${year}`;
}

export const getPaId = (user) => {
  return user?.type === userTypes.PROCUREMENT_ASSOCIATE ? user?.id : '';
}







