import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";

import CarCard from "../common/components/car-card";
import { CARD_TYPE } from "../common/constants/table.constants";
import { reset } from "../store/actions/breadCumb";
import "./all-pais.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PAGE_NAMES } from "../common/constants/page-name.constants";
import CarDetails from "../car-details";
import { fetchPAIs } from "../store/actions/pais";
import { CURRENT_BIDS_API_POLLING } from "../common/constants/timeout.constants";
import { getPaId } from "../common/utils/utils";

const OnGoingPais = ({ type, dealerId }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const pais = useSelector((state) => state.pais?.ongoing);
    const isModal = useSelector((state) => state.allCars?.isModal);
    const filters = useSelector((state) => state.allCars?.filters);
    const { userInfo } = useSelector((state) => state.allCars);


    const fetchPAIData = useCallback(() => {
        dispatch(fetchPAIs({ sortBy: 'newestFirst', page: 0, size: 2, paId: getPaId(userInfo) }, true, false));
    }, [dispatch, userInfo]);

    useEffect(() => {
        if (!pais || !pais?.data?.length) {
            dispatch(fetchPAIs({ sortBy: 'newestFirst', page: 0, size: 2, paId: getPaId(userInfo) }, true));
        }

        const interval = setInterval(fetchPAIData, CURRENT_BIDS_API_POLLING);

        return () => clearInterval(interval);
    }, [fetchPAIData]);

    const getNavURL = () => {
        return PAGE_NAMES[type] === PAGE_NAMES.cars ?
            { id: 'allcars', text: 'All Cars', link: '/viz/all-cars' }
            :
            { id: 'myDealers', text: 'My Dealers', link: '/viz/my-dealers' }
    }

    const viewAllPais = () => {
        const url = getNavURL();
        dispatch(reset([
            url,
            { id: 'allpai', text: 'View All PAIs', link: '/viz/pais' }]));
        history.push(`/viz/pais`);

    }

    return (
        <div>
            {isModal && <CarDetails type={CARD_TYPE.pais} dealerType={filters?.ocbLive?.id} />}
            {
                pais?.data?.length > 0 ?
                    <>
                        <div className="title-bar">
                            <div className="title-text">ONGOING PAIs ({pais?.totalElements})</div>
                            <div className="view-all-pais app-link" onClick={() => viewAllPais()}>
                                View All PAIs
                            </div>
                        </div>
                        <div className="flex-row ongoing-car-cards">
                            {
                                pais?.data?.map((card, index) => (
                                    <CarCard data={{ ...card?.appointmentDetails, ...card?.paiDetails }}
                                        type={CARD_TYPE.pais}
                                        key={'ongoing-pai' + index}
                                        dealerId={dealerId}
                                        dealerType={type}
                                        paiCar
                                    />
                                ))
                            }
                        </div>
                    </>
                    :
                    <div className="no-pais-data">
                        Currently, no PAIs are active. We'll showcase them here once they're initiated.
                    </div>
            }


        </div>
    )
}
export default OnGoingPais;