import { useEffect, useState } from 'react'
import './snackbar.scss'
import CrossIcon from '../../assets/images/crossIcon'
import { getCallEvent, getNotificationLevel, getPaId, hexToRGBA } from '../../utils/utils'
import { Button } from '@progress/kendo-react-buttons'
import NotificationFlag from '../../assets/images/notification-flag'
import FilledNotificationFlag from '../../assets/images/filled-notification-flag'
import { formatDateTime } from '../../../../../utils/utils'
import { getCarDetails, setCarAppointmentId, setCarDetailsTab, setModal } from '../../../store/actions/allCars'
import { CAR_DETAILS_TAB_LIST, EVENT_CAR_SOURCE } from '../../constants/table.constants'
import { useDispatch, useSelector } from 'react-redux'
import { callDealer, setCorrelationId, setNotificationModal, setOngoingCallStatus } from '../../../store/actions/myDealers'
import CallIcon from '../../assets/images/callIcon'
import { useInView } from 'react-intersection-observer'

const SnackBar = ({
  notificationData,
  timerDuration = 5000,
  triggerAlert = false,
  handleImportant = () => { },
  onVisibilityChange = () => { }
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const { filters, userInfo } = useSelector((state) => state.allCars);
  const { isCallOngoing } = useSelector((state) => state.myDealers);
  const [hasComeIntoView, setHasComeIntoView] = useState(false);



  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView && ref && !hasComeIntoView) {
      onVisibilityChange(notificationData?.id);
      setHasComeIntoView(true);
    }
  }, [inView, hasComeIntoView]);


  useEffect(() => {
    if (triggerAlert) {
      const timer = setTimeout(() => {
        setIsVisible(false)
      }, timerDuration)


      return () => clearTimeout(timer)
    }
  }, [timerDuration])



  const { color, icon: IconComponent } = getNotificationLevel(notificationData?.level);


  const goToCarDetails = (id) => {

    const dealerId = notificationData?.additionalInfo?.dealerId;
    const appointmentId = notificationData?.additionalInfo?.appointmentId;

    dispatch(setCarAppointmentId(appointmentId));
    dispatch(setCarDetailsTab(id));

    let payload = {
      appointmentCategory: filters?.ocbLive?.id,
      paId: getPaId(userInfo),
    };

    if (dealerId) {
      payload = { ...payload, dealerId }
    }

    dispatch(getCarDetails(appointmentId, payload));
    dispatch(setModal(true));
    dispatch(setNotificationModal(false));
  };

  const handleCallDealer = () => {
    const { dealerId, destinationNumber } = notificationData?.additionalInfo || {};
    const userId = userInfo?.id;

    dispatch(callDealer({ dealerId, userId, callingNumber: destinationNumber })).then((resp) => {
      dispatch(setCorrelationId(resp?.clientCorrelationId));
      getCallEvent(resp, EVENT_CAR_SOURCE.callLogs)
      dispatch(setOngoingCallStatus(true));
    }
    );
  }


  if (!isVisible) return null

  return (
    <div className={`snackbar-container ${triggerAlert ? 'snackbar-alert' : ''}`}>
      <div className='snackbar-header'>
        <div className='snackbar-icon' style={{
          backgroundColor: hexToRGBA(color, 0.05),
        }}>
          <IconComponent fill={color} width={20} height={20} />
        </div>
        <div className='snackbar-title'>
          <span className='snackbar-heading-text' style={{
            color
          }}>{notificationData?.title}</span>


          {triggerAlert ? <CrossIcon
            fill="#000"
            className="snackbar-close-icon"
            onClick={() => setIsVisible(false)}
          /> : <span className='snackbar-date-time'>{formatDateTime(notificationData?.seenAt)}</span>}
        </div>

      </div>
      <div ref={ref} className='snackbar-body'>
        <span className='snackbar-text'>{notificationData?.message}</span>
      </div>

      <div className='snackbar-footer-button'>
        {notificationData?.additionalInfo?.appointmentId ? <Button className='view-car-btn' onClick={() => goToCarDetails(CAR_DETAILS_TAB_LIST[2].id)}>View Car</Button> : null}
        {notificationData?.additionalInfo?.destinationNumber ? <Button disabled={isCallOngoing} className='view-car-btn' onClick={handleCallDealer}>
          <CallIcon />
          <span>Call Dealer</span>
        </Button> : null}

        <Button className='mark-as-imp-btn'>
          {!notificationData?.isImportant ? <NotificationFlag /> : <FilledNotificationFlag />}
          <span onClick={() => handleImportant(notificationData.id)}>{!notificationData?.isImportant ? "Mark as Important" : "Unmark as Important"}</span>
        </Button>

      </div>

      {triggerAlert ? <div
        className='timer-line'
        style={{
          backgroundColor: color,
          animationDuration: `${timerDuration / 1000}s`,
        }}
      />
        : null}
    </div>
  )
}

export default SnackBar
