import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionToken } from "@descope/react-sdk";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { getVizPanelServiceData } from "./common/constants/env.constants";
import useCopyTracker from "./common/hooks/useCopyTracker";
import { getLoginUserEmail, getUserRoles } from "../../utils/utils";
import { CALL_EVENTS, EVENT_TYPES, SegmentEvents, WEB_SOCKET_ENDPOINT } from "./common/constants/events.constants";
import { getLastEventDetails, setCallEvent, setOngoingCallStatus, setEventSource, setNotificationModal, markNotificationAsImportant, setCallingView } from "./store/actions/myDealers";
import { trackAnalytics } from "./common/services/analytics";
import AllCars from './all-cars'
import MyDealers from "./my-dealers";
import AllPAIs from "./all-pais"
import CallLogs from "./call-logs";
import Notification from "./common/components/notification";
import SnackBar from "./common/components/snackbar";
import { hasCallingAccess } from "./common/utils/viz-roles";
import { getUserInfo } from "./store/actions/allCars";

const VizPanelRoutes = () => {

  const { userInfo } = useSelector((state) => state.allCars);
  const { currentCallEvent, isEventSourceCreated, notificationPopup } = useSelector((state) => state.myDealers);

  const [notificationData, setNotificationData] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation()
  const email = getLoginUserEmail();
  const apiMetaData = getVizPanelServiceData();
  const session_id = getSessionToken();
  const roles = getUserRoles();

  const handleCopy = (copiedText) => {
    let trackData = {
      email_id: email,
      copiedText: copiedText,
    };

    trackAnalytics(SegmentEvents.Viz_copied_text, trackData);
  };


  useCopyTracker(handleCopy);

  useEffect(() => {

    if (hasCallingAccess(roles)) {
      dispatch(setCallingView(true))
    }

    if (!userInfo) {
      dispatch(getUserInfo(email));
    }

  }, [])


  useEffect(() => {
    let eventSource;

    if (userInfo && !isEventSourceCreated) {

      dispatch(getLastEventDetails({ callerIdentity: userInfo?.id })).then((resp) => {
        if (resp) {
          dispatch(setCallEvent(resp));
          dispatch(setOngoingCallStatus(true));
        }
      }
      ).catch((err) => {
        console.log(err);
      });

      eventSource = new EventSourcePolyfill(`${apiMetaData.url}${WEB_SOCKET_ENDPOINT}${userInfo?.id}&channel=SSE`, {
        headers: {
          "x-auth-key": session_id
        }
      });

      eventSource.onopen = function () {
        console.log("Event source connected");
      };

      eventSource.onmessage = function (event) {
        console.log("Event received:", event.data);
        const eventData = JSON.parse(event.data);

        if (eventData.type === EVENT_TYPES.CALL_EVENT) {
          dispatch(setCallEvent(eventData.data));
        }
        else if (eventData.type === EVENT_TYPES.NOTIFICATION_EVENT) {
          setNotificationData(eventData.data);
        }
      };

      eventSource.onerror = function (err) {
        eventSource.close();
      };


      dispatch(setEventSource(true));
    }


  }, [userInfo])


  useEffect(() => {

    if (currentCallEvent) {

      const { event } = currentCallEvent;

      if (event === CALL_EVENTS.RECORDING_FINISHED || event === CALL_EVENTS.NO_ANSWER || event === CALL_EVENTS.DISCONNECTED) {
        dispatch(setOngoingCallStatus(false));
      }
    }

  }, [currentCallEvent])


  const handleImportant = (notificationId) => {


    const markImportant = !notificationData?.isImportant;
    const receiverId = userInfo?.id;

    dispatch(markNotificationAsImportant({
      notificationId,
      isImportant: markImportant,
      receiverId
    })).then(() => {
      setNotificationData((prev) => {
        return {
          ...prev,
          isImportant: !prev.isImportant
        }
      })
    }).catch((err) => {
      showToastMessage(err.message, false);
    });


  };

  const renderComponent = () => {

    switch (location.pathname) {
      case "/viz/all-cars":
        return <AllCars />;
      case "/viz/call-logs":
        return <CallLogs />;
      case "/viz/my-dealers":
        return <MyDealers view={userInfo?.type} />;
      case "/viz/pais":
        return <AllPAIs />;
      default:
        return <div>404 Not Found</div>;
    }
  }


  if (!userInfo) return null;

  return (
    <>
      {notificationData && (
        <SnackBar
          triggerAlert
          notificationData={notificationData}
          handleImportant={handleImportant}
        />
      )}

      {notificationPopup && <Notification />}

      {renderComponent()}
    </>
  );

};

export default VizPanelRoutes;
