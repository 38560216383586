import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { filterBy } from "@progress/kendo-data-query";

import UpdateSegmentation from "../../update-segmentation";
import ViewSegmentation from '../../view-segmentation'
import useBodyOverflow from '../../../common/hooks/useBodyOverflow';

export const table_column_keys = {
  SEGMENT_ID: 'segmentId',
  NAME: 'name',
  DESCRIPTION: 'description',
  TYPE: 'type',
  ENTITY_IDS: 'entityIds',
  CREATED_BY: 'createdBy',
  UPDATE_SEGMENT: 'updateSegment'
};

export const table_column_labels = {
  SEGMENT_ID: 'Segment Id',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  TYPE: 'Type',
  ENTITY_IDS: 'Entity',
  CREATED_BY: 'Created By',
  UPDATE_SEGMENT: 'Update'
};

export const EntityCell = (event) => {
  const entityIds = event?.dataItem?.entityIds || [];
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(entityIds.slice());
  const filterData = (filter) => {
    const data = entityIds.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  useBodyOverflow(isOpen);

  return (
    <td style={{ textAlign: 'center' }}>
      {entityIds.length > 0 ? <Button fillMode="outline" themeColor="primary" onClick={() => setIsOpen(true)}>View</Button> : '-'}
      {isOpen && <ViewSegmentation setIsOpen={setIsOpen} filterChange={filterChange} isOpen={isOpen} data={data} />}
    </td>
  );
};
export const UpdateSegmentationCell = (event) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  useBodyOverflow(isModalOpen);

  return (
    <td style={{ textAlign: 'center' }}>
      <Button fillMode="outline" themeColor="primary" onClick={() => setIsModalOpen(true)}>Update</Button>
      {isModalOpen && <UpdateSegmentation
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        dataItem={event?.dataItem}
      />}
    </td>
  );
};


export const getDisplayColumns = (segmentationColumns) => {
  return Object.keys(segmentationColumns);
};