export const PANEL_MAIN_MENUS = {
	RETAIL: "RETAIL",
	OPERATION: "OPERATION",
	VAS: "VAS",
	USER: "USER",
	DEALER: "DEALER",
	CONSUMER: "CONSUMER",
	VIZ: "VIZ",
	INSPECTION: "INSPECTION",
	QC: "QC",
	SELLER_FINANCE: "Seller Finance",
	LMS: "LMS"

};

export const PANEL_SUB_MENUS = {
	RETAIL_MAKES: "MAKE",
	RETAIL_MODELS: "MODEL",
	OPERATION_CHALLAN: "CHALLAN",
	OPERATION_NOC: "NOC",
	OPERATION_VAS: "VAS",
	OPERATION_VERIFICATION: "VERIFICATION",
	VAS_ORDERS: "ORDERS",
	DEALER_FULFILLMENT: "DEALER_FULFILLMENT",
	DEALER_PAYOUT: "DEALER_PAYOUT",
	OPERATION_CHALLAN_ADMIN: "OPERATION_CHALLAN_ADMIN",
	OPERATION_CHALLAN_FINDER: "OPERATION_CHALLAN_FINDER",
	OPERATION_CHALLAN_DISPOSE: "OPERATION_CHALLAN_DISPOSE",
	OPERATION_SVCHIS: "SERVICE_HISTORY",
	OPERATION_SVCHIS_ADMIN: "SERVICE_HISTORY_ADMIN",
	RETAIL_LEAD_MON: "LEAD_MON",
	OPERATION_ADMINISTRATOR_VIEW: "ADMINISTRATOR_VIEW",
	INSPECTION_CJ_VERIFICATION: 'INSPECTION_CJ_VERIFICATION',
	CONSUMER_DATA_ADMIN: "CONSUMER_DATA_ADMIN",
	CONSUMER_VAS_PRICE_UPDATE: "CONSUMER_VAS_PRICE_UPDATE",
	VIZ_MY_DEALERS: "VIZ_MY_DEALERS",
	VIZ_ALL_CARS: "VIZ_ALL_CARS",
	BUY_BACK: "BUY_BACK",
	VIZ_CALL_LOGS: "VIZ_CALL_LOGS",
	INSPECTION_CJ_TRIGGER_MANAGER: 'INSPECTION_CJ_TRIGGER_MANAGER',
	SELLER_FINANCE_LEADS_LISTING: "SELLER_FINANCE_LEADS_LISTING",
	SELLER_FINANCE_UPLOAD_LEADS: "SELLER_FINANCE_UPLOAD_LEADS",
	INSPECTION_CJ_INCENTIVES: "INSPECTION_CJ_INCENTIVES",
	INSPECTION_MANAGER: "INSPECTION_MANAGER",
	QC_HYPO_PANEL: "QC_HYPO_PANEL",
	LMS_RTT_PANEL: "LMS_RTT_PANEL",
	LMS_RTT_DASHBOARD: "LMS_RTT_DASHBOARD",
	SEGMENTATION: "SEGMENTATION",
};

export const PANEL_MAIN_MENU_ITEMS = {
	[PANEL_MAIN_MENUS.INSPECTION]: {
		id: PANEL_MAIN_MENUS.INSPECTION,
		title: "Inspection",
		icon: "k-icon k-i-eye",
		level: 0,
		path: "/inspection",
		children: {
			[PANEL_SUB_MENUS.INSPECTION_CJ_TRIGGER_MANAGER]: {
				id: PANEL_SUB_MENUS.INSPECTION_CJ_TRIGGER_MANAGER,
				title: "Trigger Manager",
				icon: "k-icon k-i-js",
				path: "/inspection/admin",
				level: 1,
				parent: PANEL_MAIN_MENUS.INSPECTION,
			},
			[PANEL_SUB_MENUS.INSPECTION_CJ_INCENTIVES]: {
				id: PANEL_SUB_MENUS.INSPECTION_CJ_INCENTIVES,
				title: "CJ Incentives",
				icon: "k-icon k-i-page-properties",
				path: "/inspection/incentives",
				level: 1,
				parent: PANEL_MAIN_MENUS.INSPECTION,
			},
			[PANEL_SUB_MENUS.INSPECTION_CJ_VERIFICATION]: {
				id: PANEL_SUB_MENUS.INSPECTION_CJ_VERIFICATION,
				title: "CJ Footprint",
				icon: "k-icon k-i-page-properties",
				path: "/inspection/cj-footprint",
				level: 1,
				parent: PANEL_MAIN_MENUS.INSPECTION,
			},
		},
	},
	[PANEL_MAIN_MENUS.RETAIL]: {
		id: PANEL_MAIN_MENUS.RETAIL,
		title: "Retail",
		icon: "k-icon k-i-list-ordered k-i-list-numbered",
		level: 0,
		path: "/retail",
		children: {
			[PANEL_SUB_MENUS.RETAIL_LEAD_MON]: {
				id: PANEL_SUB_MENUS.RETAIL_LEAD_MON,
				title: "Lead Mon",
				path: "/retail/leadmon",
				level: 1,
			},
			[PANEL_SUB_MENUS.RETAIL_MAKES]: {
				id: PANEL_SUB_MENUS.RETAIL_MAKES,
				title: "Makes",
				path: "/retail/makes",
				level: 1,
			},
			[PANEL_SUB_MENUS.RETAIL_MODELS]: {
				id: PANEL_SUB_MENUS.RETAIL_MODELS,
				title: "Models",
				path: "/retail/models",
				level: 1,
			}
		},
	},
	[PANEL_MAIN_MENUS.VAS]: {
		key: PANEL_MAIN_MENUS.VAS,
		title: "VAS",
		icon: "k-icon k-i-select-box",
		level: 0,
		path: "/vas",
		children: {
			[PANEL_SUB_MENUS.VAS_ORDERS]: {
				key: PANEL_SUB_MENUS.VAS_ORDERS,
				title: "Orders",
				path: "/vas/orders",
				level: 1,
			},
		},
	},
	[PANEL_MAIN_MENUS.OPERATION]: {
		id: PANEL_MAIN_MENUS.OPERATION,
		title: "Operation",
		icon: "k-icon k-i-gear",
		level: 0,
		path: "/operations",
		children: {
			[PANEL_SUB_MENUS.OPERATION_CHALLAN]: {
				id: PANEL_SUB_MENUS.OPERATION_CHALLAN,
				title: "Challan",
				icon: "k-icon k-i-js",
				path: "/operations/challans",
				level: 1,
			},
			[PANEL_SUB_MENUS.OPERATION_NOC]: {
				id: PANEL_SUB_MENUS.OPERATION_NOC,
				title: "NOC",
				icon: "k-icon k-i-page-properties",
				path: "/operations/challans",
				level: 1,
			},
			[PANEL_SUB_MENUS.OPERATION_VAS]: {
				id: PANEL_SUB_MENUS.OPERATION_VAS,
				title: "VAS",
				icon: "k-icon k-i-module-manager",
				path: "/operations/challans",
				level: 1,
			},
			[PANEL_SUB_MENUS.OPERATION_CHALLAN_ADMIN]: {
				id: PANEL_SUB_MENUS.OPERATION_CHALLAN_ADMIN,
				parent: PANEL_MAIN_MENUS.OPERATION,
				title: "Challan",
				icon: "k-icon k-i-module-manager",
				path: "/operations/admin",
				level: 1,
				children: [
					{
						id: PANEL_SUB_MENUS.OPERATION_CHALLAN_FINDER,
						parent: PANEL_SUB_MENUS.OPERATION_CHALLAN_ADMIN,
						title: "Finder Admin",
						icon: "k-icon k-i-module-manager",
						path: "/operations/admin/finders",
						level: 2,
					},
					{
						id: PANEL_SUB_MENUS.OPERATION_CHALLAN_DISPOSE,
						parent: PANEL_SUB_MENUS.OPERATION_CHALLAN_ADMIN,
						title: "Disposer Admin",
						icon: "k-icon k-i-module-manager",
						path: "/operations/admin/disposers",
						level: 2,
					},
				],
			},
			[PANEL_SUB_MENUS.OPERATION_SVCHIS]: {
				id: PANEL_SUB_MENUS.OPERATION_SVCHIS,
				title: "Service History",
				icon: "k-icon k-i-page-properties",
				path: "/operations/services",
				level: 1,
			},
			[PANEL_SUB_MENUS.OPERATION_SVCHIS_ADMIN]: {
				id: PANEL_SUB_MENUS.OPERATION_SVCHIS_ADMIN,
				title: "Service History",
				icon: "k-icon k-i-module-manager",
				path: "/operations/admin/services",
				level: 1,
			},
			[PANEL_SUB_MENUS.OPERATION_VERIFICATION]: {
				id: PANEL_SUB_MENUS.OPERATION_VERIFICATION,
				title: "Verification",
				icon: "k-icon k-i-preview k-i-eye",
				path: "/operations/verification",
				level: 1,
			},
			[PANEL_SUB_MENUS.OPERATION_ADMINISTRATOR_VIEW]: {
				id: PANEL_SUB_MENUS.OPERATION_ADMINISTRATOR_VIEW,
				title: "Administrator View",
				icon: "k-icon k-i-page-properties",
				path: "/operations/adminstratorView",
				level: 1,
			},
		},
	},
	[PANEL_MAIN_MENUS.DEALER]: {
		id: PANEL_MAIN_MENUS.DEALER,
		title: "Dealer",
		icon: "k-icon k-i-user",
		level: 0,
		path: "/dealer",
		children: {
			[PANEL_SUB_MENUS.DEALER_FULFILLMENT]: {
				id: PANEL_SUB_MENUS.DEALER_FULFILLMENT,
				title: "NCD Dealer List",
				icon: "k-icon k-i-myspace",
				path: "/dealer/fulfillments",
				level: 1,
			},
			[PANEL_SUB_MENUS.DEALER_PAYOUT]: {
				id: PANEL_SUB_MENUS.DEALER_PAYOUT,
				title: "Payouts",
				icon: "k-icon k-i-track-changes-accept",
				path: "/dealer/payouts",
				level: 1,
			},
			[PANEL_SUB_MENUS.BUY_BACK]: {
				id: PANEL_SUB_MENUS.BUY_BACK,
				title: "Buy Back",
				path: "/dealer/buy-back",
				icon: "k-icon k-i-undo",
				level: 1,
			},
			[PANEL_SUB_MENUS.SEGMENTATION]: {
				id: PANEL_SUB_MENUS.SEGMENTATION,
				title: "Segmentation",
				path: "/dealer/segmentation",
				icon: "k-icon k-i-grid-layout",
				level: 1,
			}

		},
	},
	[PANEL_MAIN_MENUS.CONSUMER]: {
		id: PANEL_MAIN_MENUS.CONSUMER,
		title: "Consumer",
		icon: "k-icon k-i-attachment-45",
		level: 0,
		path: "/consumer",
		children: {
			[PANEL_SUB_MENUS.CONSUMER_DATA_ADMIN]: {
				id: PANEL_SUB_MENUS.CONSUMER_DATA_ADMIN,
				title: "Admin Settings",
				icon: "k-icon k-i-file-add",
				path: "/consumer/settings",
				level: 1,
			},
			[PANEL_SUB_MENUS.CONSUMER_VAS_PRICE_UPDATE]: {
				id: PANEL_SUB_MENUS.CONSUMER_VAS_PRICE_UPDATE,
				title: "Vas Price Update",
				icon: "k-icon k-i-track-changes-accept",
				path: "/consumer/vas/priceupdate",
				level: 1,
			},
		},
	},
	[PANEL_MAIN_MENUS.VIZ]: {
		id: PANEL_MAIN_MENUS.VIZ,
		title: "Viz Panel",
		icon: "k-icon k-i-set-column-position",
		level: 0,
		path: "/viz",
		children: {
			[PANEL_SUB_MENUS.VIZ_MY_DEALERS]: {
				id: PANEL_SUB_MENUS.VIZ_MY_DEALERS,
				title: "My Dealers",
				icon: "k-icon k-i-user",
				path: "/viz/my-dealers",
				level: 1,
			},
			[PANEL_SUB_MENUS.VIZ_ALL_CARS]: {
				id: PANEL_SUB_MENUS.VIZ_ALL_CARS,
				title: "All Cars",
				icon: "k-icon k-i-grid-layout",
				path: "/viz/all-cars",
				level: 1,
			},
			[PANEL_SUB_MENUS.VIZ_CALL_LOGS]: {
				id: PANEL_SUB_MENUS.VIZ_CALL_LOGS,
				title: "Call Logs",
				icon: "k-icon k-i-custom-call",
				path: "/viz/call-logs",
				level: 1,
			},
		},
	},
	[PANEL_MAIN_MENUS.SELLER_FINANCE]: {
		id: PANEL_MAIN_MENUS.SELLER_FINANCE,
		title: "Seller Finance",
		icon: "k-icon k-i-set-column-position",
		level: 0,
		path: '/seller-finance',
		children: {
			[PANEL_SUB_MENUS.SELLER_FINANCE_LEADS_LISTING]: {
				id: PANEL_SUB_MENUS.SELLER_FINANCE_LEADS_LISTING,
				title: "Leads",
				icon: "k-icon k-i-list-unordered k-i-list-bulleted",
				path: "/seller-finance/leads",
				level: 1,
			},
			[PANEL_SUB_MENUS.SELLER_FINANCE_UPLOAD_LEADS]: {
				id: PANEL_SUB_MENUS.SELLER_FINANCE_UPLOAD_LEADS,
				title: "Upload Leads",
				icon: "k-icon k-font-icon k-i-upload",
				path: "/seller-finance/upload-leads",
				level: 1,
			}
		}
	},
	[PANEL_MAIN_MENUS.QC]: {
		id: PANEL_MAIN_MENUS.QC,
		title: "QC",
		icon: "k-icon k-i-set-column-position",
		level: 0,
		path: "/qc",
		children: {
			[PANEL_SUB_MENUS.QC_HYPO_PANEL]: {
				id: PANEL_SUB_MENUS.QC_HYPO_PANEL,
				title: "Hypo QC Panel",
				icon: "k-icon k-i-user",
				path: "/qc/hypo-qc-panel",
				level: 1,
			},
		},
	},
	[PANEL_MAIN_MENUS.LMS]: {
		id: PANEL_MAIN_MENUS.LMS,
		title: "LMS",
		icon: "k-icon k-i-set-column-position",
		level: 0,
		path: "/lms",
		children: {
			[PANEL_SUB_MENUS.LMS_RTT_PANEL]: {
				id: PANEL_SUB_MENUS.LMS_RTT_PANEL,
				title: "RTT",
				icon: "k-icon k-i-user",
				path: "/lms/retargeting",
				level: 1,
			},
			[PANEL_SUB_MENUS.LMS_RTT_DASHBOARD]: {
				id: PANEL_SUB_MENUS.LMS_RTT_DASHBOARD,
				title: "Dashboard",
				icon: "k-icon k-i-user",
				path: "/lms/retargeting/dashboard",
				level: 1,
			},
		},
	},
};

export const USER_ROLE_KEYS = {
	TECH_SUPPORT: "TECH-SUPPORT",
	LMS_PAN_INDIA: "LMS-PAN-INDIA",
	RM: "RM",
	RA: "RA",
	FINDER: "C2B-OPS-FF-CHALLAN-FINDER",
	DISPOSE: "C2B-OPS-FF-CHALLAN-DISPOSE",
	B2B_NCD_ADMIN: "B2B_NCD_Admin",
	B2B_DEALER_PAYOUT_VIEW: "B2B-DEALER-PAYOUT-VIEW",
	B2B_DEALER_PAYOUT_FLAG: "B2B-DEALER-PAYOUT-FLAG",
	B2B_DEALER_PAYOUT_PAY: "B2B-DEALER-PAYOUT-PAY",
	C2B_RETAIL_LMS_RA: "C2B-RETAIL-LMS-RA",
	C2B_OPS_FF_SVCHIS_AGENT: "C2B-OPS-FF-SVCHIS-AGENT",
	C2B_OPS_FF_VERIFICATION_AGENT: "C2B-OPS-FF-VERIFICATION-AGENT",
	C2B_RETAIL_LMS_ADMIN: "C2B-RETAIL-LMS-ADMIN",
	C2B_OPS_FF_CHALLAN_ADMIN: "C2B-OPS-FF-CHALLAN-ADMIN",
	C2B_OPS_FF_ADMIN: "C2B-OPS-FF-ADMIN",
	C2B_CONSUMER_DATA_ADMIN: "C2B_CONSUMER_DATA_ADMIN",
	C2B_OPS_FF_SVCHIS_ADMIN: "C2B-OPS-FF-SVCHIS-ADMIN",
	C2B_OPS_FF_CHALLAN_VIEW: "C2B-OPS-FF-CHALLAN-VIEW",
	C2B_OPS_FF_SVCHIS_VIEW: "C2B-OPS-FF-SVCHIS-VIEW",
	C2B_RETAIL_VAS_ADMIN: "C2B-RETAIL-VAS-ADMIN",
	C2B_VIZ_PANEL: "C2B-VIZ-PANEL",
	C2B_VIZ_PANEL_NO_LIVE_FEED: "C2B-VIZ-PANEL-NO-LIVE-FEED",
	C2B_VIZ_PANEL_CALLING_VIEW: "C2B-VIZ-PANEL-CALLING-VIEW",
	C2B_FINANCE_SELLER_AGENT:  "C2B-FINANCE-SELLER-AGENT",
	C2B_FINANCE_SELLER_ADMIN:  "C2B-FINANCE-SELLER-ADMIN",
	C2B_OPS_CJ_VERIFY_ADMIN: "C2B-OPS-CJ-VERIFY-ADMIN",
	C2B_OPS_QC_CJ_INCENTIVE_ADMIN: "C2B-OPS-QC-CJ-INCENTIVE-ADMIN",
	C2B_OPS_QC_HYPO_ADMIN: "C2B-OPS-QC-HYPO-ADMIN",
	C2B_INSP_ANNOUNCEMENT_ADMIN: "C2B-INSP-ANNOUNCEMENT-ADMIN",
	C2B_INSP_CJ_REFERRAL_ADMIN: "C2B-INSP-CJ-REFERRAL-ADMIN",
	C2B_DEALER_BUYBACK: "C2B-DEALER-BUY-BACK",
	SEGMENTATION_READER: "SEGMENTATION-READER",
	SEGMENTATION_WRITER: "SEGMENTATION-WRITER",
};

export const ROLE_ACCESS_MENUS = {
	[USER_ROLE_KEYS.FINDER]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_CHALLAN],
	},
	[USER_ROLE_KEYS.DISPOSE]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_CHALLAN],
	},
	[USER_ROLE_KEYS.B2B_NCD_ADMIN]: {
		[PANEL_MAIN_MENUS.DEALER]: [PANEL_SUB_MENUS.DEALER_FULFILLMENT],
	},
	[USER_ROLE_KEYS.B2B_DEALER_PAYOUT_VIEW]: {
		[PANEL_MAIN_MENUS.DEALER]: [PANEL_SUB_MENUS.DEALER_PAYOUT],
	},
	[USER_ROLE_KEYS.B2B_DEALER_PAYOUT_FLAG]: {
		[PANEL_MAIN_MENUS.DEALER]: [PANEL_SUB_MENUS.DEALER_PAYOUT],
	},
	[USER_ROLE_KEYS.B2B_DEALER_PAYOUT_PAY]: {
		[PANEL_MAIN_MENUS.DEALER]: [PANEL_SUB_MENUS.DEALER_PAYOUT],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_CHALLAN_ADMIN],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_SVCHIS],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_SVCHIS_ADMIN],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_SVCHIS],
	},
	[USER_ROLE_KEYS.C2B_RETAIL_LMS_ADMIN]: {
		[PANEL_MAIN_MENUS.RETAIL]: [PANEL_SUB_MENUS.RETAIL_LEAD_MON],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_VERIFICATION_AGENT]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_VERIFICATION],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_ADMIN]: {
		[PANEL_MAIN_MENUS.OPERATION]: [
			PANEL_SUB_MENUS.OPERATION_ADMINISTRATOR_VIEW,
		],
	},
	[USER_ROLE_KEYS.C2B_OPS_CJ_VERIFY_ADMIN]: {
		[PANEL_MAIN_MENUS.INSPECTION]: [
			PANEL_SUB_MENUS.INSPECTION_CJ_VERIFICATION,
		],
	},
	[USER_ROLE_KEYS.C2B_INSP_ANNOUNCEMENT_ADMIN]: {
		[PANEL_MAIN_MENUS.INSPECTION]: [
			PANEL_SUB_MENUS.INSPECTION_CJ_TRIGGER_MANAGER,
		],
	},
	[USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN]: {
		[PANEL_MAIN_MENUS.INSPECTION]: [
			PANEL_SUB_MENUS.INSPECTION_CJ_TRIGGER_MANAGER,
			PANEL_SUB_MENUS.INSPECTION_CJ_INCENTIVES,
		],
	},
	[USER_ROLE_KEYS.C2B_INSP_CJ_REFERRAL_ADMIN]: {
		[PANEL_MAIN_MENUS.INSPECTION]: [
			PANEL_SUB_MENUS.INSPECTION_CJ_TRIGGER_MANAGER,
		],
	},
	[USER_ROLE_KEYS.C2B_CONSUMER_DATA_ADMIN]: {
		[PANEL_MAIN_MENUS.CONSUMER]: [PANEL_SUB_MENUS.CONSUMER_DATA_ADMIN],
	},
	[USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW]: {
		[PANEL_MAIN_MENUS.OPERATION]: [PANEL_SUB_MENUS.OPERATION_CHALLAN],
	},
	[USER_ROLE_KEYS.C2B_RETAIL_VAS_ADMIN]: {
		[PANEL_MAIN_MENUS.CONSUMER]: [PANEL_SUB_MENUS.CONSUMER_VAS_PRICE_UPDATE],
	},

	[USER_ROLE_KEYS.C2B_VIZ_PANEL]: {
		[PANEL_MAIN_MENUS.VIZ]: [
			PANEL_SUB_MENUS.VIZ_MY_DEALERS,
			PANEL_SUB_MENUS.VIZ_ALL_CARS,
		],
	},

	[USER_ROLE_KEYS.C2B_VIZ_PANEL_NO_LIVE_FEED]: {
		[PANEL_MAIN_MENUS.VIZ]: [
			PANEL_SUB_MENUS.VIZ_MY_DEALERS,
			PANEL_SUB_MENUS.VIZ_ALL_CARS,
		],
	},


	[USER_ROLE_KEYS.C2B_VIZ_PANEL_CALLING_VIEW]: {
		[PANEL_MAIN_MENUS.VIZ]: [
			PANEL_SUB_MENUS.VIZ_CALL_LOGS,
		],
	},
	[USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT]: {
		[PANEL_MAIN_MENUS.SELLER_FINANCE]: [
			PANEL_SUB_MENUS.SELLER_FINANCE_LEADS_LISTING,
		]
	},
	[USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN]: {
		[PANEL_MAIN_MENUS.SELLER_FINANCE]: [
			PANEL_SUB_MENUS.SELLER_FINANCE_LEADS_LISTING,
			PANEL_SUB_MENUS.SELLER_FINANCE_UPLOAD_LEADS

		]
	},
	[USER_ROLE_KEYS.C2B_OPS_QC_HYPO_ADMIN]: {
		[PANEL_MAIN_MENUS.QC]: [PANEL_SUB_MENUS.QC_HYPO_PANEL],
	},
	[USER_ROLE_KEYS.RA]: {
		[PANEL_MAIN_MENUS.LMS]: [PANEL_SUB_MENUS.LMS_RTT_PANEL],
	},
	[USER_ROLE_KEYS.RM]: {
		[PANEL_MAIN_MENUS.LMS]: [
			PANEL_SUB_MENUS.LMS_RTT_PANEL,
			PANEL_SUB_MENUS.LMS_RTT_DASHBOARD
		],
	},
	[USER_ROLE_KEYS.C2B_DEALER_BUYBACK]: {
		[PANEL_MAIN_MENUS.DEALER]: [PANEL_SUB_MENUS.BUY_BACK, PANEL_SUB_MENUS.SEGMENTATION],

	},
};
