
import { useEffect, useState } from "react";
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import styles from "./styles.module.css";

const AppChips = ({ data = [], isPlainArray = true, remove }) => {
	const [chips, setChips] = useState([]);


	useEffect(() => {
		if (isPlainArray) {
			let newData = data.map((ele, index) => {
				return {
					text: ele,
					value: "tab" + index,
				}
			});
			setChips([...newData]);

		} else {
			setChips([...data]);
		}
	}, [data.length])

	return (
		<>
			<div className={`${styles["app-chips"]}`}>
				<ChipList
					data={[...chips]}
					chip={(props) => (
						<Chip
							{...props}
							rounded="full"
							removable={!!remove}
							onRemove={(event) => {
								remove(event.target.element.innerText);
							}}

						/>
					)}
				/>

			</div>
		</>
	);
};
export default AppChips;
